import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from '../../lib/isEmpty';
import Web3 from "web3";
import EXCHANGE from 'ABI/ABI.json';
import SINGLE from 'ABI/SINGLE.json';
import MULTIPLE from 'ABI/MULTIPLE.json';
import ERC20 from 'ABI/DETH_ABI.json'
import TRADE from 'ABI/TRADE.json'
import Modal from 'react-modal';
import config from '../../lib/config'
import '../../index.css'
import { addtoken } from "actions/users";
import { addTokenCall } from "actions/users";
const useStyles = makeStyles(styles);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
const customStyles1 = {

    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minHeight: '50%',
        minWidth: '25%',
        border: '2px',
        borderRadius: '10px',
        borderStyle: 'solid',
        borderColor: '#9c27b0'
    },
    overlay: {
        zIndex: 99
    }
};

// toaster config
toast.configure();
let toasterOption = config.toasterOption

const initialFormValue = {

    "image": "",
    "swapPrice": 0,
    "tokenDesc": "",
    "tokenPrice": 0,
    "tokenCategory": "",
    "likecount": 0,
    "hashValue": "",
    "status": "",
    "deleted": 0,
    "tokenQuantity": 0,
    "balance": 0,
    "contractAddress": "",
    "type": 721,
    "minimumBid": 0,
    "endclocktime": null,
    "clocktime": null,
    "unlockcontent": "",
    "counts": 0,
    "PutOnSale": true,
    "PutOnSaleType": "",
    "ipfsimage": "",
    "tokenCounts": 0,
    "tokenName": "",
    "tokenRoyality": 0,
    "tokenBid": true,
    "tokenOwner": "",
    "tokenCreator": "",
    "timestamp": null,


}

export default function AddToken() {
    const classes = useStyles();

    const [UserAccountAddr, Set_UserAccountAddr] = useState('')
    const [Accounts, Set_Accounts] = useState('')
    const [owner_Get, set_Owner_Get] = useState('')

    const [default_service_fee721, set_default_service_fee721] = useState(0)
    const [default_service_fee1155, set_default_service_fee1155] = useState(0)
    const [default_service_feettrade, set_default_service_feetrade] = useState(0)


    const [ServiceDisable, setServiceDisable] = useState('start')
    const [tokenType, setTokenType] = useState('');
    const [tokenAddress, setTokenAddress] = useState('');
    const [modalOpen, setModalOpen] = useState(false)

    const [button1, setButton1] = useState("start")
    const [button2, setButton2] = useState("start")

    var history = useHistory();

    const { Id } = useParams();
    console.log("shgdhsjghsdfgfsdjfsdfd", Id)
    useEffect(() => {
        getConnect();
        getOwnerAddress();
        // if(!UserAccountAddr && UserAccountAddr == owner_Get){ toast.warning("You should use ADMIN ID for use this option",toasterOption)}
    }, [])

    window.onbeforeunload = function (e) {
        e = e || window.event;

        if (e) {
            if (default_service_fee721 == default_service_fee1155) {
                if (default_service_fee1155 == default_service_feettrade) {
                    //alert('done')
                }
                else {
                    e.returnValue = 'Please Work2';
                }
            }
            else {
                e.returnValue = 'Please Work2';
            }
        }
        if (default_service_fee721 == default_service_fee1155) {
            if (default_service_fee1155 == default_service_feettrade) {
                //alert('done')
            }
            else {
                return 'Sure?';
            }
        }
        else {
            return 'Sure?';
        }


    };

    const getOwnerAddress = async () => {
        try {
            var web3 = new Web3(config.BNBProvider)
            var contractCall = new web3.eth.Contract(SINGLE, config.singleContract)
            var ownerGet = await contractCall.methods.owner().call()
            var ownget = String(ownerGet).toLowerCase();
            set_Owner_Get(ownget)
        }
        catch (err) {
            console.log("getservice err", err);
        }
    }

    const getConnect = async () => {
        try {
            if (window.ethereum) {
                var web3 = new Web3(window.ethereum)
                if (web3 !== undefined) {
                    await window.ethereum.enable()
                        .then(async function () {
                            const web3 = new Web3(window.web3.currentProvider)
                            if (window.web3.currentProvider.networkVersion == config.networkVersion) {
                                if (window.web3.currentProvider.isMetaMask === true) {
                                    if (window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
                                        var currAddr = window.web3.eth.defaultAccount;
                                        var CurAddr = String(currAddr).toLowerCase();
                                        Set_UserAccountAddr(CurAddr);
                                        var result = await web3.eth.getAccounts()
                                        var setacc = result[0];
                                        console.log('Account :', setacc);
                                        Set_Accounts(setacc);
                                    }
                                }
                            }
                            else {
                                toast.warning("Please Connect Wallet", toasterOption);
                            }
                        })
                }
            }
        }
        catch (errr) {
            console.log("get connect", errr);
        }
    }

    const onChangeFUnc = async (e) => {
        var val = e.target.id
        // console.log("idssss", val)
        setServiceDisable('start')
        switch (val) {

            case 'TokenType':
                if (e.target.value) {
                    setTokenType(e.target.value)
                }
                else {
                    setTokenType(0)
                    // setServiceDisable1('error')
                }
                break;
            case 'TokenAddress':
                if (e.target.value) {
                    setTokenAddress(e.target.value)
                }
                else {
                    setTokenAddress(0)
                    // setServiceDisable1('error')
                }
                break;
        }


    }


    const addToken721 = async () => {
        var web3 = new Web3(window.ethereum)

        setButton1("process")
        try {
            if (tokenType != 0 && tokenAddress != 0) {

                var web3 = new Web3(window.ethereum)
                var contractCall = new web3.eth.Contract(SINGLE, config.singleContract)
                await contractCall
                    .methods
                    .addTokenType(Array(tokenType), (Array(tokenAddress)))
                    .send({ from: Accounts })
                    .then(async (data) => {
                        console.log("success", data);
                        setButton1("done")

                    })
            }
        } catch (e) {
            setButton1("try")
            toast.error("check address and try Again")
            console.log("error", e)
        }
    }

    const addToken1155 = async () => {
        setButton2("process")
        try {
            if (tokenType != 0 && tokenAddress != 0) {
                console.log("tokenaddadfjhgayjfgseuf", tokenType, tokenAddress);
                var web3 = new Web3(window.ethereum)
                var contractCall = new web3.eth.Contract(MULTIPLE, config.multipleContract)
                await contractCall
                    .methods
                    .addTokenType(Array(tokenType), (Array(tokenAddress)))
                    .send({ from: Accounts })
                    .then(async (data) => {
                        var calldecimal = new web3.eth.Contract(ERC20, tokenAddress);
                        console.log("daesdfcsdfcdfas", calldecimal);
                        var deci = await calldecimal.methods.decimals().call();

                        var sending = {

                            "name": tokenType,
                            "decimal": deci,
                            "address": tokenAddress,
                            action: "add"
                        }
                        var add = await addTokenCall(sending);
                        console.log("adding", add)
                        if (add) {
                            setButton2("done")
                            history.push("/currencylist");
                        }else{
                            console.log("dosn't add");
                        }
                    })
            }
        } catch (e) {
            setButton2("try")
            toast.error("check address and try Again")
            console.log("error", e);
        }
    }


    const disconnect = () => {
        Set_Accounts('')
    }

    return (
        <div>

            <div className="page_header">
                <button className="btn btn-success mr-3"><Link to="/">Back</Link></button>
                <Button className="btn btn-success" onClick={() => Accounts == '' ? getConnect() : disconnect()}><Link to="#">{Accounts != "" ? Accounts : "Connect"}</Link></Button>

            </div>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <form className={classes.form}>

                                <CardBody>
                                    <GridContainer>

                                        <GridItem xs={12} sm={12} md={4} >
                                            <CustomInput
                                                labelText="Token Type"
                                                id="TokenType"
                                                value={tokenType || ""}
                                                onChange={(e) => onChangeFUnc(e)}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                            {tokenType == 0 && <span className="text-danger">Token type can't be empty</span>}
                                            <CustomInput
                                                labelText="Token Address"
                                                id="TokenAddress"
                                                value={tokenAddress || 0}
                                                onChange={(e) => onChangeFUnc(e)}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                            {tokenAddress == 0 && <span className="text-danger">Token address can't be empty</span>}

                                        </GridItem>

                                    </GridContainer>
                                    {/* 
                                    {UserAccountAddr && UserAccountAddr == owner_Get &&
                                        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                                            <Button color="primary" onClick={() => addToken721()}>
                                                ADD TOKEN IN 721
                                            </Button>
                                            <Button color="primary" onClick={EditServiceFeesOnly}>Edit Service Fee</Button>
                                        </GridItem>}

                                    {UserAccountAddr && UserAccountAddr == owner_Get &&
                                        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                                            <Button color="primary" onClick={() => addToken1155()}>
                                                ADD TOKEN IN 1155
                                            </Button>
                                            <Button color="primary" onClick={EditServiceFeesOnly}>Edit Service Fee</Button>
                                        </GridItem>} */}

                                    {UserAccountAddr && UserAccountAddr == owner_Get &&
                                        <GridItem xs={12} sm={12} md={3} style={{ marginTop: 20 }}>
                                            <Button color="primary" onClick={() => setModalOpen(true)}>
                                                ADD TOKEN
                                            </Button>
                                            {/* <Button color="primary" onClick={EditServiceFeesOnly}>Edit Service Fee</Button> */}
                                        </GridItem>}
                                    <br />

                                </CardBody>

                            </form>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
            <Modal
                isOpen={modalOpen}
                style={customStyles1}
                contentLabel="Example Modal"
            >
                <p className="text-center">Refresh Page to Close</p>
                <p className="text-center text-danger font-weight-bold">Token should be added in  both the  contracts</p>
                <div className="servicemod">
                    <button
                        onClick={() => addToken721()}
                        disabled={button1 == "done" || button1 == "process"}
                    >
                        {button1 == "start" && "Add 721"}
                        {button1 == "process" && "processing"}
                        {button1 == "done" && "add token in 1155"}
                        {button1 == "try" && "Try again"}
                    </button>
                    <button
                        onClick={() => addToken1155()}
                        disabled={button1 != "done" || button2 == "done" || button2 == "process"}
                    >
                        {button2 == "start" && "Add 1155"}
                        {button2 == "try" && "Try again"}

                        {button2 == "process" && "processing"}
                        {button2 == "done" && "completed"}

                    </button>

                </div>
            </Modal>
        </div>
    );
}
