import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from '../../lib/isEmpty';
import Web3 from "web3";
import EXCHANGE from 'ABI/ABI.json';
import SINGLE from 'ABI/SINGLE.json';
import MULTIPLE from 'ABI/MULTIPLE.json';
import TRADE from 'ABI/TRADE.json'
import Modal from 'react-modal';
import config from '../../lib/config'
import '../../index.css'

import {AdminDataPrice} from '../../actions/users'


import {collectionEdit} from '../../actions/users'
import { walkUpBindingElementsAndPatterns } from "typescript";
//import { toast } from "react-toastify/dist/components";
const useStyles = makeStyles(styles);
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};


export default function EditServiceFee() {
    const classes = useStyles();
    const history = useHistory();
    const [formValue, setFormValue] = useState({});
    const [Admindata, setAdmindata] = useState({});
    
useEffect(()=>{
   
},[]);






    const onChange = (e) => {
        e.preventDefault();
         const { id, value } = e.target;

        let formData = { ...Admindata, ...{ [id]: value } }
        // const price = e.target.value
        console.log("fgnjhfb",formData);
        setAdmindata(formData)
    }
    
    const handleFormSubmit = async() =>{
    
    const resp =await collectionEdit(Admindata)
    console.log("hksdfbghfb",resp);
    if(resp.userValue.data.success)

    {
        console.log("dbnvkjfbdkjv comming inside")
        toast.success("collection add sucessfully",{autoClose:2000})
    }
    else{
        toast.error("Collection type or Name Mismatch",{autoClose:2000})
    }
    history.push("/CollectionEdit")

    }


   


    return (
        <div>
               
         
            <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <GridItem xs={12} sm={12} md={4}>
                    Token Counts
					<CustomInput
					labelText="Token Counts"
                 
					onChange={onChange}
					id="TokenCounts"
					formControlProps={{
						fullWidth: true
					}}
					/>
				</GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    Collection Name
					<CustomInput
					labelText="Collection Name"
					onChange={onChange}
                
					id="CollectionName"
					formControlProps={{
						fullWidth: true
					}}
					/>
				</GridItem>
                <CardFooter>
				<Button color="primary"
                onClick={handleFormSubmit}
                >Submit</Button>
			</CardFooter>
                </GridItem>
            </GridContainer>
            </div>
            
        </div>
    );
}
