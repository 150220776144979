import React, { Component, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import { Button, ProgressBar } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
// import Sidebar from '../shared/Sidebar';
import ReactDatatable, { exportToExcel } from '@ashvin27/react-datatable';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "components/CustomButtons/Button.js";

import { getCurrencyList, addTokenCall } from 'actions/users';
// import wallet_details from '../../redux/action';
import config from '../../lib/config.js';

toast.configure();


export default function CurrencyList(props) {

  var history = useHistory();


  var location = useLocation();
  const { pathname, state } = location;
  const path = pathname.split("/")[1]
  console.log("pathname,stae", pathname, state, path)


  //   const Wallet_Details = useSelector((state)=>state.wallet_detail)
  const [tokenList, setTokenList] = useState([])

  const columns = [

    {
      key: "label",
      text: "TOKEN NAME",
      className: "NFT NAME",
      align: "center",
      // sortable: true,


    },
    {
      key: "address",
      text: "Token Address",
      className: "NFT IDT",
      align: "center",
      // sortable: true,

    },
    {
      key: "delete",
      text: "Show / Hide Token",
      className: "NFT IDT",
      align: "center",
      // sortable: true,
      cell: record =>
        <div ><center><Button onClick={() => deletetoken(record)}>{(record.deleted == 1) ? "SHOW" : "HIDE"}</Button></center></div>

    }


  ]

  useEffect(() => {
    getTokenList();
  }, [])


  const getTokenList = async () => {
    var resp = await getCurrencyList();
    if (resp?.status) {
      setTokenList(resp.data)
    }
  }


  const deletetoken = async (data) => {
    data.action = "delete"
    console.log("record", data)

    var resp = await addTokenCall(data);
    if (resp?.status) {
      toast.success(resp.msg);
      getTokenList();
    } else {
      toast.error(resp.msg);

    }

  }

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Curency List",
    no_data_text: 'No Currency found!',

    button: {
      excel: true,
      print: true,
      csv: true
    },

    language: {
      length_menu: "Show _MENU_ result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last"
      }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  }

  return (

    <>


      <div>
        <div className="page_header">
          <nav aria-label="breadcrumb">

          </nav>
        </div>
        <div className="row">

          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">TOKEN LIST</h4>
                <Link to="AddCurrency">
                  <Button color="primary" type="submit">Add Currency</Button>
                </Link>
                <div className="table-responsive">
                  <ReactDatatable
                    config={config}
                    records={tokenList}
                    columns={columns}

                  />

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>

  )

}


